<template>
  <div v-cloak v-if="apiLoaded">
    <v-row class="top-progress">
      <v-col lg="4">
        <div class="green">
          <div>{{ getValuesMean([soiskIntegralIndicatorId, rabIntegralIndicatorId]) }}<span
              class="text-caption">/100</span></div>
          <p>
            Индекс<br>клиентоцентричности
          </p>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="blue">
          <div>{{ getValue(soiskIntegralIndicatorId) }}<span
              class="text-caption">/100</span></div>
          <p>
            Индекс
            клиентоцентричности
            с точки зрения
            соискателей
          </p>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="yellow">
          <div>{{ getValue(rabIntegralIndicatorId) }}<span
              class="text-caption">/100</span></div>
          <p>
            Индекс
            клиентоцентричности
            с точки зрения
            работодателей
          </p>
        </div>
      </v-col>
    </v-row>


    <h1>Показатели индекса клиентоцентричности
      <download-report :generateRequestFn="generateReportRequestFn" report-name="Отчет по показателям ИКЦ.xlsx" :report="{id: 1}">
        <div class="download-menu">
          <div class="download-menu-icon">
            <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                  fill="#0033A0"
              />
            </svg>
          </div>
        </div>
      </download-report>
    </h1>

  <template v-if="$regionAndDepartmentInfo.department">
    <div class="list passport" v-for="(group, groupNum) in getFilteredConfig()" :key="groupNum+'_group'">
      <div class="list-item-wrap">
        <v-expansion-panels multiple :value="expandedPanels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="list-item">
                <a class="list-item-name">
                  <button class="toggleSwitch">
                    <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                    </svg>
                    <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                    </svg>
                  </button>
                  <span>{{ groupNum + 1 }}. {{ group.name }}</span>
                </a>
                <div class="badge green"> {{
                    getValuesMean([group.soiskIndicatorId, group.rabIndicatorId])
                  }}/{{ getValue(group.maxScoreIndicatorId) }}
                </div>
                <div class="badge blue">{{
                    getValue(group.soiskIndicatorId)
                  }}/{{ getValue(group.maxScoreIndicatorId) }}
                </div>
                <div class="badge yellow">{{
                    getValue(group.rabIndicatorId)
                  }}/{{ getValue(group.maxScoreIndicatorId) }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels multiple>
                <v-expansion-panel class="level2" v-for="(index, indexNum) in group.indices"
                                   :key="indexNum + '_indexNum'">
                  <vue-html2pdf
                      :show-layout="false"
                      :float-layout="false"
                      :enable-download="true"
                      :preview-modal="false"
                      :paginate-elements-by-height="1400"
                      :filename="group.name + '.' + index.name"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="landscape"
                      pdf-content-width="1400px"
                      :ref="'html2Pdf.' + group.name + '.' + index.name">
                    <section slot="pdf-content">
                      <div class="list-item-wrap">
                        <v-expansion-panel-header @keyup.space.prevent>
                          <div class="list-item">
                            <a class="list-item-name">
                              <button class="toggleSwitch whiteBg">
                                <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                </svg>
                                <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                </svg>
                              </button>
                              <span>{{ indexNum + 1 }}. {{ index.name }}</span>
                              <div class="badge green"> {{
                                  getValuesMean([index.soiskResIndicatorId, index.rabResIndicatorId])
                                }}/{{ getValue(index.maxScoreResIndicatorId) }}
                              </div>
                              <div class="badge blue">{{
                                  getValue(index.soiskResIndicatorId)
                                }}/{{ getValue(index.maxScoreResIndicatorId) }}
                              </div>
                              <div class="badge yellow">{{
                                  getValue(index.rabResIndicatorId)
                                }}/{{ getValue(index.maxScoreResIndicatorId) }}
                              </div>
                            </a>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="download-menu-icon"
                               style="position: absolute; right: 10px; top: 10px; cursor: pointer"
                               @click.stop="downloadDashboard('html2Pdf.' + group.name + '.' + index.name)">
                            <svg
                                fill="none"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                                  fill="#0033A0"
                              />
                            </svg>
                          </div>
                          <ankets-graph
                              v-if="index.anketQuestionNum"
                              :filename="$regionAndDepartmentInfo.department.name + '-export'"
                              :val_s="getValue(index.soiskAnketResIndicatorId)"
                              :val_r="getValue(index.rabAnketResIndicatorId)"
                              :max_val="floor2(getValue(index.anketMaxScoreResIndicatorId))"
                              :soisk-answers="getQuestionAnswers(soiskAnketsAnswers, index.anketQuestionNum)"
                              :rab-answers="getQuestionAnswers(rabAnketsAnswers, index.anketQuestionNum)"
                              :question-text="index.anketQuestionText"
                          ></ankets-graph>
                          <checklist-status
                              v-if="index.checklistQuestionType === 'SUM'"
                              :max_val="getMaxResult(index.checklistMaxScoreResIndicatorId)"
                              :val="getValue(index.checklistResIndicatorId)"
                              :checklist-parameters="getChecklistQuestions(index.checklistIndicatorIds)"
                              :checklist-values="checklistValues"
                          >
                          </checklist-status>
                           <checklist-status
                              v-if="index.checklistQuestionType === 'COMFORT_LEVEL'"
                              :max_val="getMaxResult(index.checklistMaxScoreResIndicatorId)"
                              :val="getValue(index.checklistResIndicatorId)"
                              :checklist-parameters="getChecklistQuestions([
                                  ...comfortIndicators.accessible_env,
                                  ...comfortIndicators[`level${$regionAndDepartmentInfo.department?.level}`]])"
                              :checklist-values="checklistValues"
                              :title="`ЦЗН ${$regionAndDepartmentInfo.department?.level}-го уровня`"
                           >
                           </checklist-status>

<!--                          <template v-if="subIndex.baseDynamics">-->
<!--                            <p>{{ floor2(computeBaseDynamics() * 100 - 100) }}%</p>-->
<!--                          </template>-->
<!--                          <template v-if="subIndex.stymul">-->
<!--                            <p>Проведено мероприятий, стимулирующих рост числа соискателей:-->
<!--                              {{ dashboardData.Ps3 ? dashboardData.Ps3 * 5 : '-' }}-->
<!--                              <br>-->
<!--                            </p>-->
<!--                          </template>-->
<!--                          <template v-if="subIndex.comfort">-->
<!--                            <v-row>-->
<!--                              <v-col lg="12">-->
<!--                                <div class="graph-wrap checklist-wrap">-->
<!--                                  <div class="d-flex flex-column">-->
<!--                                    <div>{{ dashboardData.KP }}<span>/{{ dashboardData.KP_max_value }}</span></div>-->
<!--                                    <p class="mt-auto mb-0">Чек-лист</p>-->
<!--                                  </div>-->
<!--                                  <div class="checklist">-->
<!--                                    <div>-->
<!--                                      <span></span>-->
<!--                                      Уровень комфортности (без учета ручных оценок): {{ getLevelValue() }} <br>-->
<!--                                      Уровень 3: {{ getLevelValueConsts().l3 }} /-->
<!--                                      {{ getLevelValueConsts().level3Indices.length }} <br>-->
<!--                                      Уровень 2: {{ getLevelValueConsts().l2 }} /-->
<!--                                      {{ getLevelValueConsts().level2Indices.length }}<br>-->
<!--                                      Уровень 1: {{ getLevelValueConsts().l1 }} /-->
<!--                                      {{ getLevelValueConsts().level1Indices.length }}<br>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </v-col>-->
<!--                            </v-row>-->
<!--                          </template>-->
                        </v-expansion-panel-content>
                      </div>
                    </section>
                  </vue-html2pdf>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </template>

    <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="$regionAndDepartmentInfo.department?.name + '.' + 'demographic'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        ref="html2Pdf.demographic">
      <section slot="pdf-content">
        <h2 style="margin-top: 70px;">Социально-демографическая характеристика опрошенных</h2>

        <v-tabs>
          <v-tab>пол</v-tab>
          <v-tab>возраст</v-tab>
          <v-tab>категории граждан</v-tab>

          <v-tab-item>
            <GChart
                type="ColumnChart"
                :data="getGenderChartData()"
                :options="genderChartOptions"
            />
          </v-tab-item>

          <v-tab-item>
            <GChart
                type="BarChart"
                :data="getAgechartData()"
                :options="ageChartOptions"
            />
          </v-tab-item>

          <v-tab-item>
            <GChart
                type="BarChart"
                :data="getCategoriesChartData()"
                :options="categoriesChartOptions"
            />
          </v-tab-item>
        </v-tabs>
      </section>
    </vue-html2pdf>
    <h2 style="margin-top: 60px;">Динамика изменения</h2>

    <v-row>
      <v-col lg="6">


        <p>Период сбора информации</p>
        <v-row>
          <v-col lg="6">

            <v-menu v-model="startDateMenu"
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    :value="dynamicStartDate | dateFormat"
                ></v-text-field>
              </template>
              <v-date-picker
                  locale="ru-RU"
                  no-title
                  scrollable
                  :max="maxDate"
                  v-model="dynamicStartDate"
                  @input="startDateMenu=false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col lg="6">
            <v-menu v-model="endDateMenu"
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    :value="dynamicEndDate | dateFormat"
                ></v-text-field>
              </template>
              <v-date-picker
                  locale="ru-RU"
                  no-title
                  scrollable
                  :max="maxDate"
                  v-model="dynamicEndDate"
                  @input="endDateMenu=false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-select v-if="!isRegionMode()"
                  label="Группа показателей"
                  :items="getFilteredConfig()"
                  item-text="name"
                  return-object
                  clearable
                  v-model="dynamicIndexGroup"></v-select>
        <v-select v-else
                  label="Группа показателей"
                  disabled></v-select>
        <v-select v-if="dynamicIndexGroup != null"
                  label="Показатель"
                  return-object
                  :items="dynamicIndexGroup.indices"
                  item-text="name"
                  clearable
                  v-model="dynamicIndex"></v-select>
        <v-select v-else
                  label="Показатель"
                  disabled></v-select>

        <v-select label="Тип"
                  :items="dynamicTypes"
                  item-text="title"
                  item-value="value"
                  v-model="dynamicType"></v-select>
        <v-btn class="btn btn-primary"
               @click="getDynamicDiagram()">
          Применить
        </v-btn>
      </v-col>
      <v-col v-if="chartData != null" lg="6">
        <div class="line-wrap">
          <vue-html2pdf
              :show-layout="false"
              :float-layout="false"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              :filename="$regionAndDepartmentInfo.department?.name + '.' + 'dynamic'"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="1400px"
              ref="html2Pdf.dynamic">
            <section slot="pdf-content">
              <GChart
                  type="LineChart"
                  :data="chartData"
                  :options="chartOptions"
              />
            </section>
          </vue-html2pdf>
        </div>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {GChart} from "vue-google-charts/legacy";
import VueHtml2pdf from 'vue-html2pdf';

import AnketsGraph from "@/components/elements/icc/AnketsGraph";
import ChecklistStatus from "@/components/elements/icc/ChecklistStatus";
import {loadConfigByQuarter} from "@/modules/IccRepository";
import {getDomainConfigById} from "@/modules/api.configs";
import {
  getByDomainAndIndicatorId,
  getCznIndicatorsByIndicatorId,
  getCznIndicatorsByPeriod,
  getRegionIndicatorsByPeriod
} from "@/modules/api.indicators";
import {loadAnketFormData, loadRegionAnketFormData} from "@/modules/ankets";
import {
  generatePeriodsBetween,
  getPeriodByDate,
  getPeriodEndDate,
  getPeriodStartDate,
  previousPeriod
} from "@/modules/periods";
import {getIndicatorsByContext} from "@/modules/api.indicators";
import api from "@/modules/api";
import DownloadReport from "@/components/elements/DownloadReport.vue";

export default {
  name: "ClientCentricityMain",
  components: {
    DownloadReport,
    ChecklistStatus,
    GChart,
    VueHtml2pdf,
    AnketsGraph
  },
  props: ['period', 'checklistDomain', 'periodSpan', 'periods'],
  watch: {
    $regionAndDepartmentInfo: {
      async handler() {
        await Promise.all([
          this.initializeIndicators(),
          this.initializeAnkets(),
          this.initializeChecklistAnswers()
        ])
        this.$forceUpdate();

        if (this.isRegionMode()){
          this.dynamicIndexGroup = null;
          this.dynamicIndex = null;
        }
      },
      deep: true
    },
    period: {
      async handler() {
        await Promise.all([
          this.initializeIndicators(),
          this.initializeAnkets(),
          this.initializeChecklistAnswers()
        ])
        this.$forceUpdate();
      }
    }
  },

  data: () => ({
    configId: '66cb5a8b8b564c5a3ee11219',
    indicatorsDomainName: 'client_centricity_indicators',
    soiskIntegralIndicatorId: 'd1aad13e-8edc-486c-8518-b678ee1c375c',
    rabIntegralIndicatorId: '0cd87dc7-4d6b-466f-b097-34dcab5d1bf3',
    soiskAnketConfigId: 167679,
    rabAnketConfigId: 167682,
    values: [],
    maxResultValues: [],
    checklistValues: [],
    prevChecklistValues: [],
    questionsDict: {},
    newConfig: {},
    comfortIndicators: {},

    regionIccData: [], //JSON.parse(regionIccDataString),
    soiskAnswers: [], //JSON.parse(anketSoiskString),
    rabAnswers: [], //JSON.parse(anketRabString),
    iccDataChecklists: [], // JSON.parse(iccDataChecklistsString),
    cznSoiskAnswers: [],
    cznRabAnswers: [],
    checklistsConfig: null,

    startDateMenu: false,
    endDateMenu: false,

    dynamicStartDate: null,
    dynamicEndDate: null,

    dynamicTypes: [
      {title: 'Индекс клиентоцентричности', value: 'valueSummary'},
      {title: 'Индекс клиентоцентричности с точки зрения соискателей', value: 'valueApplicant'},
      {title: 'Индекс клиентоцентричности с точки зрения работодателей', value: 'valueEmployer'},
    ],
    dynamicType: 'valueSummary',
    dynamicIndexGroup: null,
    dynamicIndex: null,

    apiLoaded: false,
    maxDate: new Date().toJSON().slice(0, 10),
    expandedPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8],

    columnChartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      chartArea: {width: '100%', left: 0, top: 0, height: 150},
      vAxis: {
        gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },
      hAxis: {color: '#333', minSpacing: 20},
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },
      bar: {
        groupWidth: 30
      }
    },

    ageChartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      chartArea: {width: '100%', left: 100, top: 10, height: 300},
      height: 380,
      vAxis: {
        minValue: 0,
        textStyle: {
          fontSize: 10
        }
      },
      hAxis: {color: '#333'},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 12,
          color: '#000',
        }
      },
      series: {
        0: {color: '#69B3E7'},
      }
    },

    categoriesChartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      chartArea: {width: '100%', left: 450, top: 10, height: 400},
      height: 480,
      vAxis: {
        minValue: 0, maxValue: 120,
        textStyle: {
          fontSize: 10
        }
      },
      hAxis: {color: '#333'},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 12,
          color: '#000',
        }
      },
      series: {
        0: {color: '#69B3E7'},
      }

    },

    chartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      chartArea: {width: '100%', left: 80, top: 30, height: 300},
      width: 500,
      height: 380,
      vAxis: {minValue: 0, maxValue: 45,},
      hAxis: {color: '#333', minSpacing: 20},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },

    },

    chartData: null,

    genderChartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      height: 380,
      chartArea: {width: '100%', left: 0, top: 0, height: 250},
      vAxis: {
        gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },
      hAxis: {color: '#333', minSpacing: 20},
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },
      bar: {
        groupWidth: 100
      },
      series: {
        0: {color: '#69B3E7'},
        1: {color: '#69B3E7'},
        2: {color: '#69B3E7'},
      }
    }

  }),

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  methods: {
    downloadDashboard(refName) {
      this.$refs[refName][0].generatePdf();
    },

    floor2(val) {
      return Math.floor(val * 100) / 100
    },

    async getChecklistsConfig() {
      let config = await loadConfigByQuarter(this.quarter)
      return config.configParams
    },

    getChecklistConfigParameters(groupId, indexId) {
      return this.checklistsConfig.indexGroups.find(e => e.id === groupId)?.indices.find(e => e.id === indexId)?.parameters
    },

    filterChecklistValues(groupId, indexId, quarter) {
      return this.checklistValues.filter(e => e.groupId === groupId && e.indexId === indexId && e.quarter === quarter)
    },

    getChecklistValue(groupId, indexId, parameterId, quarter) {
      return this.checklistValues.find(e => e.groupId === groupId && e.indexId === indexId && e.parameterId === parameterId && e.quarter === quarter)
    },

    _roundValue(v){
      return v !== undefined ? Math.round(+v * 100) / 100 : v;
    },

    _getValue(data, indicatorId) {
      let v = data.find(e => e['indicatorId'] === indicatorId)?.value
      return this._roundValue(v);
    },

    getValue(indicatorId) {
      return this._getValue(this.values, indicatorId);
    },

    getMaxResult(indicatorId) {
      return this._getValue(this.maxResultValues, indicatorId);
    },

    // часто нужна сумма двух индикаторов
    // тогда getValue каждого по отдельности может дать undefined,
    // а вот их сумма без специальной фукнции вернет уже NaN
    // эта функция вернет undefined, если одно из слагаемых undefined
    getValuesMean(indicatorIdArray) {
      let sum = 0
      for (let id of indicatorIdArray) {
        let v = this.getValue(id)
        if (v === undefined) {
          return undefined
        }
        sum += v
      }
      sum /= indicatorIdArray.length
      return Math.round(sum * 100) / 100
    },

    async initializeChecklistAnswers(){
      if(!this.$regionAndDepartmentInfo.department){
        return
      }
      const [reqValsCurr, reqValsPrev] = await Promise.all([
        getCznIndicatorsByPeriod(this.checklistDomain, this.$regionAndDepartmentInfo.department.id, this.period), // данные текущего периода
        getCznIndicatorsByPeriod(this.checklistDomain, this.$regionAndDepartmentInfo.department.id, previousPeriod(this.period, this.periodSpan)) // данные предшествующего периода
      ])
      if (reqValsCurr.ok && reqValsPrev.ok) {
        this.checklistValues = reqValsCurr.payload
        this.prevChecklistValues = reqValsPrev.payload
      } else {
        alert('Ошибка загрузки данных')
      }
    },


    async loadValues(){
      this.values = []
      let req
      if (this.$regionAndDepartmentInfo.department) {
        req = await getCznIndicatorsByPeriod(this.indicatorsDomainName, this.$regionAndDepartmentInfo.department.id, this.period)
      } else {
        req = await getRegionIndicatorsByPeriod(this.indicatorsDomainName, this.$regionAndDepartmentInfo.region.code, this.period, 'EMPTY')
      }
      if (!req.ok) {
        alert('Ошибка получения данных с сервера: не удается загрузить показатели')
      }
      this.values = req.payload
    },

    async loadMaxResultValues(){
      this.maxResultValues = []
      let req
      req = await getIndicatorsByContext(this.indicatorsDomainName, "icc_max")
      if (!req.ok) {
        alert('Ошибка получения данных с сервера: не удается загрузить max result показатели')
      }
      this.maxResultValues = req.payload
    },

    async initializeIndicators() {
      await this.loadValues();
      await this.loadMaxResultValues();

      this.$forceUpdate()
    },

    async getAnketsParsed(configId) {
      if(!this.$regionAndDepartmentInfo.department){
        return
      }
      let req
      if (this.$regionAndDepartmentInfo.department.type === 'manager_czn') {
        req = await loadRegionAnketFormData(configId,
            this.$regionAndDepartmentInfo.region.code,
            getPeriodStartDate(this.period),
            getPeriodEndDate(this.period))
      } else {
        const anketQuerySpec = {
          configId: configId,
          cznId: this.$regionAndDepartmentInfo.department.id,
          startDate: getPeriodStartDate(this.period),
          endDate: getPeriodEndDate(this.period),
          size: 100000
        }
        req = await loadAnketFormData(anketQuerySpec)
      }
      if (!req.ok) {
        alert('Ошибка загрузки данных: не удалось загрузить анкеты')
      }
      const ankets = req.payload
      return ankets.map(anket => this.parseAnswers(anket.answers))
    },

    // функция переписана с питона в модуле расчета индексов
    // должна быть синхронизация здесь, в модуле расчета и в используемом конфиге анкет
    // см. пояснения к фукнции в модуле расчета
    parseAnswers(answersStr) {
      let result = {};
      // на выходе будет массив объектов с ключами ниже
      const questionsOrder = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9', 'q10', 'q11', 'q12', 'q12.1', 'q13', 'q13.1', 'q13.2', 'q14', 'q15', 'q16', 'q17', 'q18', 'q19']
      let i = 11
      let allAnswers = JSON.parse(answersStr);
      result.genderAnswers = allAnswers[1]
      let a = allAnswers[0]
      if (a[i] === 1) {
        a.splice(i + 1, 0, null)
      }
      i = i + 2
      if (a[i] === 1) {
        a.splice(i + 1, 0, null)
        a.splice(i + 2, 0, null)
      }
      const dict = {}
      i = 0
      for (let q of questionsOrder) {
        dict[q] = a[i]
        i++
      }
      result.answers = dict;
      return result;
    },

    // возвращает массив ответов из answers (объект this.soskAnswers или this.rabAnswers) по конкретному вопросу
    getQuestionAnswers(answers, anketQuestionNum) {
      return answers.map(e => e['q' + anketQuestionNum])
    },

    async initializeAnkets() {
      this.soiskAnketsAnswers = []
      this.rabAnketsAnswers = []
      this.genderAnswers = []

      if (!this.$regionAndDepartmentInfo.department){
        return
      }
      const soiskAnketsAnswersResult = await this.getAnketsParsed(this.soiskAnketConfigId);
      this.soiskAnketsAnswers = soiskAnketsAnswersResult.map(x => x.answers);

      const rabAnketsAnswersResult = await this.getAnketsParsed(this.rabAnketConfigId);
      this.rabAnketsAnswers = rabAnketsAnswersResult.map(x => x.answers);

      this.genderAnswers = [
          ...soiskAnketsAnswersResult.map(x => x.genderAnswers),
          ...rabAnketsAnswersResult.map(x => x.genderAnswers)];

      console.log(this.soiskAnketsAnswers, this.rabAnketsAnswers)
    },

    isRegionMode() {
      return !this.$regionAndDepartmentInfo.department;
    },

    // фильтрует конфиг: вопросы c onlyManagerCzn только для УЦЗН, остальные только для ТЦЗН для CENTRALIZED regions
    getFilteredConfig(){
      if (this.$regionAndDepartmentInfo.region.netType === 'DECENTRALIZED') {
        return this.newConfig;
      }
      const origialConfigCopy = JSON.parse(JSON.stringify(this.newConfig))
      let config = []
      for (let group of origialConfigCopy) {
        group.indices = group.indices.filter(g => g.onlyManagerCzn === (this.$regionAndDepartmentInfo.department.type === 'manager_czn'))
        if (group.indices.length > 0) {
          config.push(group)
        }
      }
      return config
    },

    // на входе список ид индикаторов чеклиста
    // возвращает из questionDict только те, ид которых есть в параметре
    // (то есть по ид вопросв получаются их тексты)
    getChecklistQuestions(checklistIndicatorIds){
      let res = []
      for(let indicatorId of checklistIndicatorIds){
        let indicator = this.questionsDict[indicatorId]
        indicator['indicatorId'] = indicatorId
        res.push(indicator)
      }
      return res
    },

    _getDynamicPeriods() {
      const start = (this.dynamicStartDate && new Date(this.dynamicStartDate)) ?? new Date();
      const end = (this.dynamicEndDate && new Date(this.dynamicEndDate)) ?? new Date();

      const startPeriod = getPeriodByDate(start, this.periodSpan);
      const endPeriod = getPeriodByDate(end, this.periodSpan);

      const periodsBetween = generatePeriodsBetween(startPeriod, endPeriod, this.periodSpan);
      const periodsBetweenValues = periodsBetween.map(x => x.value);
      return this.periods.filter(period => periodsBetweenValues.includes(period.value));
    },

    _getResponse(req){
      if (req.ok) {
        return req.payload;
      }
      alert('Ошибка загрузки данных');
    },

    _getMean(values) {
      if (values.some(v => v === undefined)) {
        return undefined;
      }
      const sum = values.reduce((acc, v) => acc + v, 0);
      return Math.round(sum / values.length * 100) / 100;
    },

    async _getDynamicDiagramData(reqFn, soiskIndexId, rabIndexId) {
      if (this.dynamicType == "valueSummary") {
        const [soiskRes, rabRes] = await Promise.all([reqFn(soiskIndexId), reqFn(rabIndexId)]);
        const resGroups = [...this._getResponse(soiskRes), ...this._getResponse(rabRes)].reduce((acc, item) => {
          if (acc[item.period]) {
            acc[item.period].push(this._roundValue(+item.value));
            return acc;
          }
          acc[item.period] = [this._roundValue(+item.value)];
          return acc;
        }, {});

        return Object.keys(resGroups).reduce((acc, key) => {
          acc[key] = resGroups[key].length == 2 ? this._getMean(resGroups[key]) : undefined;
          return acc;
        }, {});
      }

      const indexId = this.dynamicType == "valueApplicant" ? soiskIndexId : rabIndexId;
      return this._getResponse(await reqFn(indexId)).reduce((acc, item) => {
        acc[item.period] = this._roundValue(+item.value);
        return acc;
      }, {});
    },

    async getDynamicDiagram() {
      this.dynamicPeriods = this._getDynamicPeriods();
      const { region, department } = this.$regionAndDepartmentInfo;

      let data = {};
      if (this.dynamicIndexGroup != null && this.dynamicIndex != null && this.dynamicType != null) {
        data = await this._getDynamicDiagramData(
            indId => getCznIndicatorsByIndicatorId(this.indicatorsDomainName, department.id, indId),
            this.dynamicIndex.soiskResIndicatorId,
            this.dynamicIndex.rabResIndicatorId);
      } else if (this.dynamicIndexGroup != null && this.dynamicType != null) {
        data = await this._getDynamicDiagramData(
            indId => getCznIndicatorsByIndicatorId(this.indicatorsDomainName, department.id, indId),
            this.dynamicIndexGroup.soiskIndicatorId,
            this.dynamicIndexGroup.rabIndicatorId);
      } else if (this.dynamicType != null) {
        data = await this._getDynamicDiagramData(
            indId => getByDomainAndIndicatorId(this.indicatorsDomainName, indId,
                `regionCode=${region.code}&cznId=${this.isRegionMode() ? 0 : department.id }`),
            this.soiskIntegralIndicatorId,
            this.rabIntegralIndicatorId);
      }

      const result = [["Квартал", "Индекс", {role: 'annotation'}]];
      for (const period of this.dynamicPeriods.reverse()) {
        const value = data[period.value];
        result.push([period.text, +value, `${value}`]);
      }
      this.chartData = result;
    },

    getGenderChartData() {
      const M = this.genderAnswers.reduce(
          (accumulator, currentValue) => {
            return currentValue[0] === 0 ? accumulator + 1 : accumulator
          }, 0
      )
      const F = this.genderAnswers.reduce(
          (accumulator, currentValue) => {
            return currentValue[0] === 1 ? accumulator + 1 : accumulator
          }, 0
      )
      return [
        ["Пол", "Количество ", {role: 'annotation'}],
        ["Мужской", M, M],
        ["Женский", F, F],
      ]
    },

    getAgechartData() {
      const cznGenderAnswers = this.genderAnswers;

      return [
        ["Возраст", "Количество "],
        ["Младше 18 лет", getAgeCat(0)],
        ["18-19 лет", getAgeCat(1)],
        ["20-29 лет", getAgeCat(2)],
        ["30-39 лет", getAgeCat(3)],
        ["40-49 лет", getAgeCat(4)],
        ["50-59 лет", getAgeCat(5)],
        ["60-69 лет", getAgeCat(6)],
        ["70 лет и старше", getAgeCat(7)],
      ]

      function getAgeCat(cat) {
        return cznGenderAnswers.reduce(
            (accumulator, currentValue) => {
              return currentValue[1] === cat ? accumulator + 1 : accumulator
            }, 0)
      }
    },

    getCategoriesChartData() {
      const cznGenderAnswers = this.genderAnswers;

      return [
        ["Категория", "Количество "],
        ["Граждане предпенсионного возраста", getInvalidCat(0)],
        ["Пенсионеры", getInvalidCat(1)],
        ["Инвалиды", getInvalidCat(2)],
        ["Дети-сироты", getInvalidCat(3)],
        ["Уволенные по сокращению штатов или ликвидации организации", getInvalidCat(4)],
        ["Выпускники школ", getInvalidCat(5)],
        ["Выпускники средне-профессиональных и высших учебных заведений", getInvalidCat(6)],
        ["Женщины с детьми от 0 до 6 лет", getInvalidCat(7)],
        ["Не работающие более года", getInvalidCat(8)],
        ["Не относятся ни к одной из перечисленных категорий граждан", getInvalidCat(9)],
      ]

      function getInvalidCat(cat) {
        return cznGenderAnswers.reduce(
            (accumulator, currentValue) => {
              return currentValue[2]?.length && currentValue[2].indexOf(cat) !== -1 ? accumulator + 1 : accumulator
            }, 0)
      }
    },

    generateReportRequestFn(report){
        const regionCode = this.$regionAndDepartmentInfo.region.code;
        const cznId = this.$regionAndDepartmentInfo.department?.id;
        const report_title = "Показатели индекса клиентоцентричности";
        const url = `/reports/generate/${this.indicatorsDomainName}/${report.id}?report_title=${report_title}&period=${this.period}&region_code=${regionCode}&czn_id=${cznId}`;
        return api.get(url);
    }
  },

  async beforeMount() {
    let req = await getDomainConfigById(this.indicatorsDomainName, this.configId)
    if (!req.ok) {
      alert('Ошибка получения данных с сервера: не удается загрузить конфигурацию')
    }
    this.newConfig = JSON.parse(req.payload.content.groups)
    this.questionsDict = JSON.parse(req.payload.content.questions)
    this.comfortIndicators = req.payload.content.comfortIndicators
    await Promise.all([
      this.initializeIndicators(),
      this.initializeAnkets(),
      this.initializeChecklistAnswers()
    ])


    this.apiLoaded = true;
    this.expandedPanels = [];

  }
}
</script>

<style lang="scss">
@import "../../styles/main";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}


.clientindex {
  div.blue {
    background: #E1F0FA !important;
    color: #2F80ED !important;
  }

  div.green {
    background: #E2F5EA !important;
    color: #27AE60 !important;
  }

  div.yellow {
    background: #FCF4DB !important;
    color: #F2994A !important;
  }

  dv.darkblue {
    background: #E5EBF5 !important;
    color: #0033A0 !important;
  }

  .badge {
    height: 30px;
    margin-left: 4px;
    margin-right: 0;
    font-size: 13px;
    font-weight: 700;
  }


  .v-slide-group__content.v-tabs-bar__content {
    justify-content: space-around !important;
  }

  .v-window-item {
    padding-top: 50px;
  }

  .v-expansion-panel .level2 {
    padding-top: 5px;

    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }

  .list .list-item-wrap .list-item-name {
    max-width: none;

    .toggleSwitch {
      align-self: center;
    }
  }

  .list .list-item-wrap .list-item {
    justify-content: flex-start;

    .v-progress-circular {
      margin-left: 0;

      .v-progress-circular__info {
        font-size: 16px;
        font-weight: 600;
        display: block;

        .text-caption {
          font-size: 10px !important;
          vertical-align: baseline;
          display: inline;
        }
      }
    }
  }

  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.graph-wrap {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 30px 30px 0 30px;

  h3 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    text-align: center;
    margin-bottom: 10px;
  }

  .graph-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;

    div:first-child {
      font-size: 36px;
      line-height: 30px;

      span {
        font-size: 12px;
        vertical-align: text-top;
      }
    }

    div:last-child {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1F;
    }

    &.text-blue {
      color: #2F80ED;

      div:last-child span {
        color: #2F80ED;
      }
    }

    &.text-orange {
      color: #F2994A;

      div:last-child span {
        color: #F2994A;
      }
    }

  }

  &.checklist-wrap {
    padding: 20px;
    display: block;
    gap: 30px;
    background: #F3F3F3;
    border-radius: 20px;
    min-height: 130px;

    > div:first-child {
      width: 140px;

      div {
        color: #2F80ED;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;

        span {
          vertical-align: text-top;
          font-size: 12px;
          color: #2F80ED;
        }
      }
    }

    .checklist > div {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      gap: 10px;

      span {
        width: 14px;
      }

      svg {
        margin-top: 4px;
      }

      &.checked {
        color: #0033A0;
      }
    }

    .v-progress-circular__info {
      font-size: 16px;
      display: block;
      font-weight: 600;

      .text-caption {
        font-size: 7px !important;
        vertical-align: baseline;
      }
    }
  }
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

</style>
