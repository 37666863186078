<template>
  <div>
    <div @click="downloadAsync(report)">
      <slot></slot>
    </div>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import api from "@/modules/api";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'DownloadReport',
  components: {LoaderDialog},
  props: ['generateRequestFn', 'reportName', "report"],
  data: () => ({
    loading: false,
  }),
  methods: {
    async downloadAsync() {
      if (this.loading){
        return
      }
      try {
        if (!this.report?.id) {
          alert('Отчет не найден!')
          return
        }
        this.loading = true;
        const response = (await this.generateRequestFn(this.report)).payload;

        this.downloadTaskId = response.task_id;
        await this.checkTaskStatus();
      } catch (error) {
        console.error('Error generating report:', error);
        alert('Ошибка при генерации отчета!');
        this.loading = false;
      }
    },

    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload;
        if (response.status === 'SUCCESS') {
          await this.downloadFile();
        } else {
          setTimeout(this.checkTaskStatus, 2000);
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        alert('Ошибка при проверке статуса задачи!');
        this.loading = false;
      }
    },

    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload;
        this.$downloadBlob(blob, this.reportName);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Ошибка при скачивании файла!');
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>
</style>